
import { Vue, Component } from "vue-property-decorator";
import { Editor } from "@/components";
@Component({
  name: "EditorPage",
  components: { Editor },
})
export default class EditorPage extends Vue {
  private text1 = "defalutText1";
  private text2 = "defaultText2";
  private disabled = true;

  mounted() {
    setTimeout(() => {
      this.text1 = "<p>这是接口获取到的数据text1</p>";
      this.text2 = "这是接口获取到的数据text2";
    }, 3000);
  }

  handleInput(html: string) {
    this.text2 = html;
  }
}
